:root {
    --button-blue-background: transparent;
    --button-blue-color-on-hover: #fff;
    --button-blue-border-on-hover: 1px solid #2780e3;
    --button-blue-background-on-hover: #2780e3;
    --button-blue-border: 1px solid #2780e3;
    --button-blue-color: #2780e3;
}

.button {
    all: unset;

    padding: .375rem .75rem;
    cursor: pointer;
    text-decoration: none;
    display: block;
}

.button:hover {
    text-decoration: none;
}

.button.blue {
    padding: .375rem .75rem;

    background-color: var(--button-blue-background);
    border: var(--button-blue-border);
    color: var(--button-blue-color);
    transition: all 0.25s ease-in-out;
}

.button.blue:hover {
    background-color: var(--button-blue-background-on-hover);
    color: var(--button-blue-color-on-hover);
    border: var(--button-blue-border-on-hover);
}

.input.default {
    outline: none;
    padding: .375rem .75rem;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.form-inline {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.form-inline > label {
    margin: 0;
    padding: 0;
}

.select2-default {
    width: auto;
    padding: 1rem 2rem;
}

.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    word-wrap: break-word;
    background-clip: border-box;
    padding: 1.25rem;
    border: 1px solid rgba(0, 0, 0, .125);

    margin-top: 2rem;
    margin-bottom: 2rem;
}

.card-white {
    background-color: #fff;
}

.card-red {
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.5);
}

.card > .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.card-body > .fields {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.card-body > .fields > .heading {
    display: flex;
    align-items: center;
    padding: 0 24px;
}

.card-body > .fields > .field {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-left: 1rem;
    border-left: solid 1px #e0e0e0;
}

.card-body > .fields > .field > .field-label {
    margin-right: 1rem;
}