html, body {
    margin: 0;
    padding: 0;

    font-size: 15px;
    line-height: 1.5rem;
}

.navbar {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    height: 100%;

    padding: 0.5rem 0.5rem;
}

.navbar-list-item {
    display: block;
    position: relative;
    flex-direction: row;
    color: var(--nav-text-color);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--nav-background);
    transition: background-color 0.1s ease-in-out;
    text-decoration: none;
}

.navbar-list-item:hover {
    text-decoration: none;
}

.navbar-list-item.active {
    background-color: #2780e3;
}

.navbar-list-item > p {
    display: inline;
}

.navbar-list-header {
    color: var(--nav-header-color);
    margin-top: 1rem;

    padding: 0.5rem 0.75rem;
}

.navbar-list-item-icon {
    display: inline-block;
    font-size: 1.1rem;
    width: 1.6rem;
    margin-left: 0.05rem;
    margin-right: 0.66rem;
    text-align: right;
    font-weight: 900;
}

.navbar-list-item:hover {
    background-color: rgba(255, 255, 255, .1);
    color: var(--nav-hover-text-color);
}

#app_page_header {
    padding: .75rem 1.5rem;
    border-bottom: 1px solid rgb(222, 226, 230);

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

#app_page_header__title {
    padding-right: 1rem;
    border-right: 1px solid rgb(222, 226, 230);
    color: rgb(55, 58, 60);
    font-family: 'SourceSans-Light', sans-serif;

    line-height: 1.5rem;
    font-size: 1.5rem;
}

#app_router_outlet {
    padding: 1rem 2rem;
}
