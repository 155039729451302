:root {
    --body-background: #f4f6f9;
    --text-color: #1f2d3d;

    /* Mantine Header (top) */
    --header-background: #fff;
    --header-border-bottom: 1px solid #ccc;

    /* Mantine Navbar (left side) */
    --nav-background: rgb(52, 58, 64);
    --nav-text-color: #c2c7d0;
    --nav-header-color: rgba(255, 255, 255, 0.3);
    --nav-hover-text-color: #fff;
}
