body, html {
    margin: 0;
    padding: 0;

    /*background-color: #1f2d3d;*/
}

#form {
    margin-top: 2rem;
    background-color: white;
    border-radius: 15px;
    padding: 1.5rem 4rem;
}

image {
    width: 100%;
}

.center {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    margin-top: 5rem;
}

#submit {
    margin-left: auto;
    margin-right: auto;
    background-color: #2780e3;
    color: white;
}