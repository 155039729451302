.navbar-item-list {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 2%;
    cursor: pointer;
    user-select: none;
}

.navbar-item {
    display: block;
    position: relative;
    flex-direction: row;
    color: var(--nav-text-color);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--nav-background);
    transition: background-color 0.1s ease-in-out;
    text-decoration: none;
}

.navbar-item.active {
    background-color: #2780e3;
}

.navbar-item > p {
    display: inline;
}

.navbar-item-icon {
    display: inline-block;
    font-size: 1.1rem;
    width: 1.6rem;
    margin-left: 0.05rem;
    margin-right: 0.66rem;
    text-align: right;
    font-weight: 900;
}

.navbar-item:hover {
    background-color: rgba(255, 255, 255, .1);
    color: var(--nav-hover-text-color);
}
